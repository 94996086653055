import { Move } from "./model/Move";
import { BoardModel } from "./model/BoardModel";
import { Player } from "./model/Player";

export function getBoardModel(moves: Move[]) {
  const board: BoardModel = {
    columns: [[], [], [], [], [], [], []]
  };

  moves.forEach((move, index) => {
    board.columns[move].push({
      player: index % 2 === 0 ? Player.PLAYER_1 : Player.PLAYER_2
    });
  });

  return board;
}
