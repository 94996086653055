import React from "react";
import { GRID_SQUARE_SIZE } from "../constants";

interface Props {
  x: number;
  y: number;
}

export function GridSquare({ x, y }: Props) {
  return (
    <div
      style={{
        position: "absolute",
        height: GRID_SQUARE_SIZE + "px",
        width: GRID_SQUARE_SIZE + "px",
        boxShadow: "inset 0 0 0 10px #FFC940",
        left: x * GRID_SQUARE_SIZE + "px",
        top: y * GRID_SQUARE_SIZE + "px",
        zIndex: 3
      }}
    />
  );
}
