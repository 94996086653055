import React from "react";
import { GRID_SQUARE_SIZE } from "../constants";
import { GridSquare } from "./GridSquare";
import { GameToken } from "./GameToken";
import { BoardModel } from "../model/BoardModel";

interface Props {
  boardModel: BoardModel;
  winningPositions: { x: number; y: number }[];
}

export function Board({ boardModel, winningPositions }: Props) {
  const gridSquares: JSX.Element[] = [];

  for (let x = 0; x < 7; x++) {
    for (let y = 0; y < 6; y++) {
      gridSquares.push(<GridSquare key={x + y * 1000} x={x} y={y} />);
    }
  }

  const tokens: JSX.Element[] = [];

  boardModel.columns.forEach((column, x) => {
    let y = 0;
    column.forEach(token => {
      const emphasize = winningPositions.some(position => {
        return position.x === x && position.y === y;
      });

      tokens.push(
        <GameToken
          emphasize={emphasize}
          key={y + x * 1000}
          x={x}
          y={y++}
          player={token.player}
        />
      );
    });
  });

  return (
    <div
      style={{
        position: "relative",
        width: 7 * GRID_SQUARE_SIZE + "px",
        height: 6 * GRID_SQUARE_SIZE + "px",
        border: "20px solid #2965CC",
        borderTop: "5px solid #FFC940",
        zIndex: 3
      }}
    >
      {gridSquares}
      {tokens}
    </div>
  );
}
