import React from "react";
import { Board } from "./Board";
import { style, keyframes } from "typestyle";
import { Move } from "../model/Move";
import { random, Omit } from "lodash";
import { PLAYER_1_COLOR, PLAYER_2_COLOR } from "../constants";
import { getBoardModel } from "../getBoardModel";
import { observer, useObservable } from "mobx-react-lite";
import { transaction } from "mobx";
import { BoardModel } from "../model/BoardModel";
import uuid from "uuid";

const appClassName = style({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh"
});

const topPanelClassName = style({
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
});

const inputContainerClassName = style({
  position: "relative",
  flexGrow: 1,
  display: "flex"
});

const inputClassName = style({
  flexGrow: 1,
  marginRight: "20px",
  padding: "20px",
  paddingLeft: "60px",
  borderRadius: "5px",
  border: "1px solid #ddd",
  outline: "none",
  $nest: {
    "&:focus": {
      borderColor: "rgb(71, 193, 228)"
    }
  }
});

const buttonClassName = style({
  padding: "20px",
  outline: "none",
  borderRadius: "5px",
  cursor: "pointer",
  $nest: {
    "&:focus": {
      borderColor: "rgb(71, 193, 228)"
    }
  }
});

const gameInfoClassName = style({
  display: "flex",
  justifyContent: "space-between",
  borderTop: "1px solid #ccc",
  background: "#ddd",
  padding: "10px 30px",
  fontSize: "12px"
});

const gameControlsClassName = style({
  borderTop: "1px solid #ccc",
  background: "#eee",
  padding: "20px 20px",
  display: "flex"
});

const tokenIndicatorClassName = style({
  position: "absolute",
  width: "30px",
  height: "30px",
  lineHeight: "30px",
  textAlign: "center",
  fontSize: "18px",
  fontWeight: "bold",
  color: "white",
  marginLeft: "15px",
  marginTop: "15px",
  borderRadius: "100%"
});

const gameStatusClassName = style({
  padding: "20px",
  color: "#333",
  fontSize: "20px",
  fontWeight: "bold",
  animation: `${keyframes({
    from: {
      opacity: 0,
      transform: "translateY(10px)"
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)"
    }
  })} .3s ease`,
  animationFillMode: "both"
});

export const App = observer(() => {
  const game = useObservable({
    id: null as null | string,
    player1Url: { temp: "", actual: "" },
    player2Url: { temp: "", actual: "" },
    moves: [] as Move[],
    status: "Welcome to Connect Four!",
    winningPositions: [] as { x: number; y: number }[]
  });

  const boardModel = getBoardModel(game.moves);

  return (
    <div className={appClassName}>
      <div className={topPanelClassName}>
        <Board
          winningPositions={game.winningPositions}
          boardModel={boardModel}
        />
        <div key={game.status} className={gameStatusClassName}>
          {game.status}
        </div>
      </div>
      <div>
        <div className={gameInfoClassName}>
          <span>
            Built in AIs: <b>random</b>, <b>random-legal</b>
          </span>
          <span>
            AI URL should accept a POST with a JSON structured as{" "}
            <span style={{ fontFamily: "monospace" }}>
              {"{"}gameId: string, moves: number[]{"}"}
            </span>{" "}
            returning{" "}
            <span style={{ fontFamily: "monospace" }}>
              {"{"}nextMove: number{"}"}
            </span>
          </span>
        </div>
        <div className={gameControlsClassName}>
          <div className={inputContainerClassName}>
            <div
              className={tokenIndicatorClassName}
              style={{
                background: PLAYER_1_COLOR
              }}
            >
              1
            </div>
            <input
              value={game.player1Url.temp}
              onChange={e => (game.player1Url.temp = e.currentTarget.value)}
              className={inputClassName}
              placeholder="Player 1 (AI URL or Built-in AI)"
            />
          </div>
          <div className={inputContainerClassName}>
            <div
              className={tokenIndicatorClassName}
              style={{
                background: PLAYER_2_COLOR
              }}
            >
              2
            </div>
            <input
              value={game.player2Url.temp}
              onChange={e => (game.player2Url.temp = e.currentTarget.value)}
              className={inputClassName}
              placeholder="Player 2 (AI URL or Build-in AI)"
            />
          </div>
          <button className={buttonClassName} onClick={startGame}>
            Start Game
          </button>
        </div>
      </div>
    </div>
  );

  async function startGame() {
    transaction(() => {
      if (game.player1Url.temp.trim() === "") {
        game.player1Url.temp = "random-legal";
      }

      if (game.player2Url.temp.trim() === "") {
        game.player2Url.temp = "random-legal";
      }

      game.player1Url.actual = game.player1Url.temp;
      game.player2Url.actual = game.player2Url.temp;
      game.moves = [];
      game.winningPositions = [];
      game.id = uuid.v4();
      game.status = "Starting new game...";
    });
    await sleep(1500);
    executeTurn(game.id!);
  }

  async function executeTurn(gameId: string) {
    if (isGameOver()) return;

    const player = game.moves.length % 2 === 0 ? 1 : 2;

    const nextMoveUrl =
      player === 1 ? game.player1Url.actual : game.player2Url.actual;
    game.status = `Waiting for Player ${player} to move...`;

    let nextMove: Move;

    try {
      nextMove = await getNextMove(nextMoveUrl, game.moves, gameId);
      if (isGameOver()) return;
    } catch (error) {
      console.error(error);
      game.status = `Error: Something went wrong during Player ${player}'s turn.`;
      return;
    }

    await sleep(600);
    if (isGameOver()) return;

    transaction(() => {
      game.moves.push(nextMove);
      game.status = `Player ${player} dropped a token in column ${nextMove +
        1}`;
    });

    await sleep(1000);
    if (isGameOver()) return;

    const boardModel = getBoardModel(game.moves);

    if (hasIllegalMove(boardModel)) {
      game.status = `Player ${player} made an illegal move`;
      return;
    }

    const winningPositions = getWinningPositions(boardModel);
    console.log(winningPositions);
    if (winningPositions.length > 0) {
      transaction(() => {
        game.winningPositions = winningPositions;
        game.status = `Player ${player} won!`;
      });
      return;
    }

    if (hasDraw(boardModel)) {
      game.status = `Draw`;
      return;
    }

    executeTurn(gameId);

    function isGameOver() {
      return gameId !== game.id;
    }
  }
});

function hasIllegalMove(boardModel: BoardModel) {
  return boardModel.columns.some(column => {
    return column.length > 6;
  });
}

function getWinningPositions(boardModel: BoardModel) {
  interface Check {
    deltaX: number;
    deltaY: number;
  }

  const horizontalCheckPlan: Check[] = [
    { deltaX: 1, deltaY: 0 },
    { deltaX: 2, deltaY: 0 },
    { deltaX: 3, deltaY: 0 }
  ];
  const verticalCheckPlan: Check[] = [
    { deltaX: 0, deltaY: 1 },
    { deltaX: 0, deltaY: 2 },
    { deltaX: 0, deltaY: 3 }
  ];
  const diagonalTopLeftToBottomRightCheckPlan: Check[] = [
    { deltaX: 1, deltaY: 1 },
    { deltaX: 2, deltaY: 2 },
    { deltaX: 3, deltaY: 3 }
  ];
  const diagonalBottomLeftToTopRightCheckPlan: Check[] = [
    { deltaX: 1, deltaY: -1 },
    { deltaX: 2, deltaY: -2 },
    { deltaX: 3, deltaY: -3 }
  ];

  const checkPlans = [
    horizontalCheckPlan,
    verticalCheckPlan,
    diagonalBottomLeftToTopRightCheckPlan,
    diagonalTopLeftToBottomRightCheckPlan
  ];

  let winningPositions: { x: number; y: number }[] = [];

  const hasWinner = boardModel.columns.some((column, xPos) => {
    return column.some((token, yPos) => {
      return checkPlans.some(plan => {
        const tempWinningPositions: { x: number; y: number }[] = [
          {
            x: xPos,
            y: yPos
          }
        ];

        const hasWinner = plan.every(check => {
          const x = xPos + check.deltaX;
          const y = yPos + check.deltaY;

          tempWinningPositions.push({ x, y });

          return token.player === getSquarePlayer(x, y);
        });

        if (hasWinner) {
          winningPositions = tempWinningPositions;
        }

        return hasWinner;
      });
    });
  });

  return hasWinner ? winningPositions : [];

  function getSquarePlayer(x: number, y: number) {
    if (x < 0 || x >= 7 || y < 0 || y >= 6) {
      return null;
    }

    const token = boardModel.columns[x][y];

    if (token === undefined) {
      return null;
    }

    return token.player;
  }
}

function hasDraw(boardModel: BoardModel) {
  return boardModel.columns.every(column => {
    return column.length === 6;
  });
}

async function getNextMove(nextMoveUrl: string, moves: Move[], gameId: string) {
  if (nextMoveUrl === "random") {
    return random(0, 6) as Move;
  }

  if (nextMoveUrl === "random-legal") {
    let nextMove: Move;

    do {
      nextMove = random(0, 6) as Move;
    } while (hasIllegalMove(getBoardModel([...moves, nextMove])));

    return nextMove;
  }

  const response = await fetch(nextMoveUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ moves, gameId })
  });

  const result = (await response.json()) as { nextMove: Move };

  if (
    typeof result !== "object" ||
    result === null ||
    result.nextMove < 0 ||
    result.nextMove > 6
  ) {
    throw new Error("Invalid response");
  }

  return parseInt(result.nextMove.toString(), 10) as Move;
}

async function sleep(milliseconds: number) {
  await new Promise(r => setTimeout(r, milliseconds));
}
