import React from "react";
import { Player } from "../model/Player";
import { GRID_SQUARE_SIZE, PLAYER_1_COLOR, PLAYER_2_COLOR } from "../constants";
import { style, keyframes } from "typestyle";

interface Props {
  x: number;
  y: number;
  player: Player;
  emphasize: boolean;
}

const fadeInDownClassName = style({
  animation: `${keyframes({
    "0%": {
      opacity: 0,
      transform: "translateY(-400px) scale(.1)"
    },
    "20%": {
      opacity: 1,
      transform: "translateY(-400px) scale(.7)"
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0px) scale(.7)"
    }
  })} 1s ease-in`,
  animationFillMode: "both"
});

export function GameToken({ x, y, player, emphasize }: Props) {
  return (
    <div
      className={fadeInDownClassName}
      style={{
        position: "absolute",
        zIndex: 1,
        left: x * GRID_SQUARE_SIZE + "px",
        top: (5 - y) * GRID_SQUARE_SIZE + "px",
        width: GRID_SQUARE_SIZE + "px",
        height: GRID_SQUARE_SIZE + "px",
        borderRadius: "100px",
        boxShadow: emphasize ? "inset 0 0 0 10px #5588ff" : undefined,
        background: player === Player.PLAYER_1 ? PLAYER_1_COLOR : PLAYER_2_COLOR
      }}
    />
  );
}
