import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import { normalize } from "csstips";
import { forceRenderStyles } from "typestyle";

normalize();
forceRenderStyles();

const container = document.createElement("div");
document.body.appendChild(container);

ReactDOM.render(<App />, container);
